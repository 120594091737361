@font-face {
  font-family: 'Sora';
  src: url('assets/fonts/Sora-Regular.ttf'), local('Sora') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('assets/fonts/Sora-SemiBold.ttf'), local('Sora SemiBold'), format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('assets/fonts/Sora-Bold.ttf'), local('Sora Bold') format('truetype');
  font-weight: 700;
  font-style: normal;
}
